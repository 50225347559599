@import "~core/abstract/all";
@import "~bootstrap/scss/mixins";

.b-hero-text-overlay {
    position: relative;
    margin: auto;
    padding: 0;
    width: 100%;
    .content{
        position: absolute;    
        padding: 3%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        text-align: center;
        width: 85%;
    }

    .btn-flex-ctn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5%;

    }
    
    .disclaimer-link {
        text-decoration: underline;   
    }

    .disclaimer-pretext {
        margin-top: 3%;
    }

    @include media-breakpoint-up(sm) { 
        width: 100%;
        padding: unset;

         .content{
            position: absolute;    
            padding: 3%;
            text-align: unset;
            width: unset;
        }

        .btn-flex-ctn{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 5%;
        }
        
    }
    @include media-breakpoint-up(md) { 
        
    }
    @include media-breakpoint-up(ls) { 
       
    }
    @include media-breakpoint-up(xxl) {

    }
}

.not-full{
    width: unset;
}

.translate-unset{
    transform: unset !important;
}


