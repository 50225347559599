@import "../../../abstract/all";
@import "~bootstrap/scss/mixins";
@import "~core/experience/components/commerceAssets/heroWithTextOverlay";

.b-hero-text-overlay {
    h2 {
        font-size: clamp(32px, 2.78vw, 60px);
        text-transform: unset;
    }

    p {
        font-size: clamp(14px, 2.8vw, 28px);
    }

    @include media-breakpoint-up(sm) {
        h2 {
            font-size: clamp(28px, 4vw, 54px);
            margin-bottom: .2vw;
        }

        p {
            font-size: clamp(14px, 1.2vw, 23px);
        }
    }
}
