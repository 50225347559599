@import "~bootstrap/scss/mixins";

@function fw($type) {
    $fontWeight: map-get($font-weights, $type);

    @return $fontWeight;
}

@each $font-weight in map-keys($font-weights) {
    $fontWeightValue: #{map-get($font-weights, $font-weight)};

    .fw-#{"" + $font-weight} {
        font-weight: $fontWeightValue !important;
    }
}

@each $letter-spacing in map-keys($letter-spacings) {
    $letterSpacingValue: #{map-get($letter-spacings, $letter-spacing)};

    .letter-spacing-#{$letter-spacing} {
        letter-spacing: $letterSpacingValue !important;
    }

    .letter-spacing-d-#{$letter-spacing} {
        @include media-breakpoint-up(ls) {
            letter-spacing: $letterSpacingValue !important;
        }
    }

    .letter-spacing-mb-#{$letter-spacing} {
        @include media-breakpoint-down(ls) {
            letter-spacing: $letterSpacingValue !important;
        }
    }
}

@each $line-height in map-keys($line-heights) {
    $lineHeightValue: #{map-get($line-heights, $line-height)};

    .line-height-#{$line-height} {
        line-height: $lineHeightValue !important;
    }

    .line-height-d-#{$line-height} {
        @include media-breakpoint-up(ls) {
            line-height: $lineHeightValue !important;
        }
    }

    .line-height-mb-#{$line-height} {
        @include media-breakpoint-down(ls) {
            line-height: $lineHeightValue !important;
        }
    }
}

@each $font-size in map-keys($font-sizes) {
    $fontSizeValue: #{map-get($font-sizes, $font-size)};

    .font-size-#{$font-size} {
        font-size: $fontSizeValue !important;
    }

    .font-size-d-#{$font-size} {
        @include media-breakpoint-up(ls) {
            font-size: $fontSizeValue !important;
        }
    }

    .font-size-mb-#{$font-size} {
        @include media-breakpoint-down(ls) {
            font-size: $fontSizeValue !important;
        }
    }
}

@for $i from 8 through 150 {
    .fs-m-#{$i} {
        @include media-breakpoint-down(xs) {
            font-size: $i + px !important;
        }
    }
    .fs-m-#{$i}-fluid {
        @include media-breakpoint-down(xs) {
            font-size: calc(#{$i}/430 * 100vw ) !important;
        }
    }
    .fs-t-#{$i} {
        @include media-breakpoint-between(sm, md) {
            font-size: $i + px !important;
        }
    }
    .fs-t-#{$i}-fluid {
        @include media-breakpoint-between(sm, md) {
            font-size: calc(#{$i}/768 * 100vw ) !important;
        }
    }
    .fs-d-#{$i} {
        @include media-breakpoint-between(ls, xl) {
            font-size: $i + px !important;
        }
    }
    .fs-d-#{$i}-fluid {
        @include media-breakpoint-between(ls, xl) {
            font-size: calc(#{$i}/1440 * 100vw) !important;
        }
    }
    .fs-dxl-#{$i} {
        @include media-breakpoint-up(xxl) {
            font-size: $i + px !important;
        }
    }
}

.not-full-bleed{
    @include media-breakpoint-up(ls) {
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 100px!important;
        padding-right: 100px!important;
    }
}

.not-full-bleed-dxl {
    @include media-breakpoint-up(xxl) {
        max-width: 1720px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 100px!important;
        padding-right: 100px!important;
    }
}

.not-full-bleed-dxl-p0 {
    @include media-breakpoint-up(xxl) {
        max-width: 1720px;
        margin-left: auto;
        margin-right: auto;

    }
}

.text-transform-none {
    text-transform: none!important;
}

.y-axis-center {
    top: unset !important;
    transform: translateY(50%) !important;
    bottom: 50% !important;
}

.y-axis-center-2 {
    top: unset !important;
    transform: translateY(50%) !important;
}

.x-axis-center {
    transform: translateX(50%) !important;
    right: 50% !important;;
}